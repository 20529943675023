.cart {
    background-color: #ffffff;
    border-radius: 4px;
    margin: 0.5rem;
}
.desktop{
    display: none;
}

.mobile{
    position: fixed;
    width: 100%;
    bottom: 0px;
    background-color: white;
}
.itemQuantity-desktop{
    display: none !important;
}
.itemQuantity-mobile{
    display: block;
}
.empty {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

.cart-footer {
    padding: 1rem;
}

.image-container {
    max-height: 10rem;
    max-width: 8rem;
}

.checkout-btn {
    text-transform: none;
    font-weight: 700;
    font-size: 1.1rem;
    height: 3.5rem;
}


.empty-cart-icon {
    width: 3rem !important;
    height: 3rem !important;
    color: #aaa;
}

.cart-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (min-width:900px) {
    .empty{
        display: flex;
    }
    .desktop{
        display: block;
    }
    .mobile{
        display:none;
        
    }
    .itemQuantity-desktop{
        display: flex!important;
        padding-left: 10px;
    }
    .itemQuantity-mobile{
        display: none!important;
    }
    .checkout_mob{
        display: none!important;
    }
}